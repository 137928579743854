import styles from './homepage.module.scss';
import SearchIcon from '@mui/icons-material/Search';
import Cards from 'components/Cards';
import { useRef, useContext, useEffect, useState, useCallback } from 'react';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import routes from 'constants/routes';
import axios from 'axios';
import { getMaxDate } from 'helper/common';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Button, Pagination, styled } from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'helper/firebase';
import { useSnackBar } from 'hoc/snackbar';
import { SearchOutlined, LocationOn } from '@mui/icons-material';
import ShareActivityModal from 'components/ShareActivityModal';
import AddActivityModal from 'components/AddActivityModal';
import FilterSection from 'components/FilterSection';
import UsersSection from 'components/UsersSection';
import DropdownSort from 'components/DropdownSort';
import RatingStats from 'components/RatingStats/RatingStats';
import { fetchPlace } from 'helper/fetchPlace';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import { Helmet } from 'react-helmet-async';
import FAVICON from 'assets/icons/favicon.ico';
import { objectToQueryString } from 'helper/objectToQueryString';

const CURRENT_LOCATION_API = process.env['REACT_APP_IP_API'];
const BASE_URI = process.env['REACT_APP_BASE_URI'];
const WEATHER_API_KEY = process.env['REACT_APP_WEATHER_API_KEY'];
const WEATHER_BASE_URI = 'https://api.openweathermap.org/data/2.5/forecast';

const StyledButton = styled(Button)(({ bg, customColor }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: customColor ? customColor : bg === 'none' ? '#1F62AF' : '#fff',
    borderRadius: '5px',
    width: '123px',
    height: '41px',
    fontSize: 18,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const SortOptions = [
    {
        label: 'recommended',
        value: 'Recommended',
    },
    {
        label: 'like',
        value: 'Like',
    },
    {
        label: 'done',
        value: 'Done',
    },
    // {
    //     label: 'lists',
    // },
];

const Homepage = () => {
    const [whenDate, setWhenDate] = useState(new Date());
    const { state, dispatch } = useContext(StoreContext);
    const { userLocation, cardsData, searchedData, totalActivities } = state;
    console.log(state, ':state home');

    // const [searchTerm, setSearchTerm] = useState('');
    const [userLocationSearch, setUserLocationSearch] = useState('');
    const filterValues = JSON.parse(localStorage.getItem('filterValues')) || '';
    const navigate = useNavigate();
    const { snackBarError, snackBarSuccess, snackBarWarning } = useSnackBar();
    const [openShareModal, setOpenShareModal] = useState(false);
    const [openActivityModal, setOpenActivityModal] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [selectSort, setSelectSort] = useState('');
    const [isFilterStick, setIsFilterSticky] = useState(false);
    const [autocompleteCities, setAutocompleteCities] = useState([]);
    const [autocompleteErr, setAutocompleteErr] = useState('');
    const [showFilterInMobile, setShowFilterInMobile] = useState(false);
    const [offset, setOffset] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // const [totalActivities, setTotalActivities] = useState(0);

    const locationInputRef = useRef(null);
    const searchInputRef = useRef(null);

    const queryParam = {};
    searchParams.forEach((value, key) => {
        if (key == 'search_term' || key == 'Location') {
            queryParam[key] = value;
        } else if (key == 'Latitude' || key == 'Longitude') {
            queryParam[key] = Number(value);
        } else if (key == 'page') {
            queryParam[key] = value;
        } else {
            queryParam[key] = value?.split(',');
        }
    });
    console.log(queryParam, ':queryParamForPage');

    const [currentPage, setCurrentPage] = useState(+queryParam?.page || 1);

    const [coordinates, setCoordinates] = useState(
        Object.keys(queryParam).length > 0
            ? [queryParam?.Longitude, queryParam?.Latitude]
            : [userLocation?.longitude, userLocation?.latitude],
    );

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const debounceBack = useCallback(
        debounce(async (newValue) => {
            const res = await fetchPlace(newValue);
            console.log(res);
            !autocompleteCities.includes(newValue) &&
                res.features &&
                setAutocompleteCities(res.features.map((place) => place.place_name));
            setCoordinates(res.features[0]?.center);
            dispatch({
                type: actions.SET_LOCATION,
                payload: {
                    city: newValue,
                    latitude: res.features[0]?.center[1],
                    longitude: res.features[0]?.center[0],
                },
            });
            res.error ? setAutocompleteErr(res.error) : setAutocompleteErr('');
        }, 1000),
        [],
    );

    const handleCityChange = (e) => {
        setUserLocationSearch(e.target.value);
        debounceBack(e.target.value);
    };

    const getCurrentIp = async () => {
        try {
            console.log('getting ip...');
            // setLoading(true, 'Fetching Current Location...');
            const res = await axios.get(CURRENT_LOCATION_API);
            if (res.status !== 200) throw res.statusText;
            const data = res.data;
            setUserLocationSearch(res.data.city || res.data.country_name);
            // dispatch({
            //     type: actions.SET_LOCATION,
            //     payload: data,
            // });
            // setLoading(false);
            return data;
        } catch (error) {
            console.log(error);
            // setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true, 'Getting activities by search term...');

            const payload = {
                search_term: searchInputRef?.current?.value,
                Location: userLocation?.city,
                Latitude: Number(userLocation?.latitude),
                Longitude: Number(userLocation?.longitude),
            };
            const option = {
                'Content-Type': 'application/json',
            };
            const res = await axios.post(`${BASE_URI}/activities`, payload, option);
            if (res.status !== 200) throw res.statusText;
            const { data } = res;
            dispatch({
                type: actions.SET_DATA,
                payload: {
                    data: data.data,
                    totalActivities: data.total_rows
                },
            });

            const queryString1 = new URLSearchParams(payload).toString();
            console.log(userLocation, ':ul');
            // console.log(coordinates, ':coo');
            // const localCoordinates = [
            //     userLocation?.longitude,
            //     userLocation?.latitude,
            // ];
            // localStorage.setItem('coordinates', JSON.stringify(localCoordinates));
            navigate(`/?${queryString1}`);
            setLoading(false);
        } catch (error) {
            console.log(error);
            snackBarError(JSON.stringify(error.toString()));
            setLoading(false);
        }
    };

    const getActivitiesList = async () => {
        try {
            setLoading(true, 'Fetching Activities List...');
            let payload = {
                offset: (currentPage - 1) * 100,
                limit: 100,
            };

            if (Object.keys(queryParam).length > 0) {
                payload = {
                    ...payload,
                    ...queryParam,
                };
            }

            // let payload = Object.keys(queryParam).length > 0 ? queryParam : {};
            let options = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            if ('page' in payload) {
                delete payload.page;
            }

            const res = await axios.post(`${BASE_URI}/activities`, payload, options);
            if (res.status !== 200) throw res.statusText;
            const { data } = res;

            // setTotalActivities(res.data.total_rows);
            dispatch({
                type: actions.SET_DATA,
                payload: {
                    data: data.data,
                    totalActivities: data.total_rows
                },
            });
            // const userLoc = await getCurrentIp();
            // console.log(userLoc, ':loc');
            // const coordinates = [userLoc?.longitude, userLoc?.latitude];
            // // console.log(coordinates, ':coordinates');
            // localStorage.setItem('coordinates', JSON.stringify(coordinates));

            let newPayload = {
                page: Math.floor(payload.offset / 100) + 1,
                ...payload,
            };

            if ('limit' in newPayload) {
                delete newPayload.limit;
            }
            if ('offset' in newPayload) {
                delete newPayload.offset;
            }

            console.log(newPayload, ':new')

            const queryString = objectToQueryString(newPayload)

            // const queryParams = Object.keys(queryParam).map((key) => {
            //     const paramValue = filterParams[key]
            //         .map((tag) => encodeURIComponent(tag))
            //         .join(',');
            //     return `${key}=${paramValue}`;
            // });

            // const queryString1 = new URLSearchParams(newPayload).toString();
            console.log(queryString, ':string');
            setLoading(false);
            navigate(`/?${queryString}`);
        } catch (error) {
            setLoading(false);
        }
    };

    const getCurrentData = (data) => {
        let enteredDate = moment().format('MM-DD-YYYY');
        let currData = data.list.find((item) => {
            let dtDate = moment(item.dt_txt).format('MM-DD-YYYY');
            return dtDate == enteredDate;
        });
        return currData;
    };

    const getCelcius = (k) => {
        let c = Number(k) - 273.15;
        return c.toFixed(0).toString();
    };

    const getVisibilityInitialValue = (value) => {
        console.log(value);
        switch (value.toLowerCase()) {
            case 'clouds':
                return 'cloudy';
            case 'rain':
                return 'rainy';
            case 'clear':
                return 'clearsky';
            default:
                break;
        }
    };

    const getWeatherDetails = async (ipData) => {
        try {
            setLoading(true, 'Fetching Weather Details...');
            const url = queryString.stringifyUrl({
                url: WEATHER_BASE_URI,
                query: {
                    appid: WEATHER_API_KEY,
                    lon: coordinates.length > 0 ? coordinates[0] : ipData?.longitude,
                    lat: coordinates.length > 0 ? coordinates[1] : ipData?.latitude,
                },
            });
            const res = await axios.get(url);
            if (res.status !== 200) throw res.statusText;
            const data = res.data;
            let todayData = getCurrentData(data);
            const weatherDataInfo = {
                temperature: getCelcius(todayData.main.temp),
                wind: todayData.wind.speed,
                visibility: getVisibilityInitialValue(todayData.weather[0].main),
            };
            dispatch({
                type: actions.SET_WEATHER_INFO,
                payload: weatherDataInfo,
            });
            // setWeatherInfo({
            //     temperature: getCelcius(todayData.main.temp),
            //     wind: todayData.wind.speed,
            //     visibility: getVisibilityInitialValue(todayData.weather[0].main),
            // });
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    // useEffect(() => {
    //     if((userLocationSearch === '' || !userLocationSearch) && userLocation?.city !== '') {
    //         setUserLocationSearch(userLocation?.city)
    //     }

    // }, [userLocation])

    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
    
     
        if (windowWidth < 770) {
            if((userLocationSearch === '' || !userLocationSearch) && userLocation?.city !== '') {
                setUserLocationSearch(userLocation?.city)
            }
        }
    
       
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, [windowWidth, userLocation]);

    useEffect(() => {
        if(Object.keys(queryParam).length === 0) {
            getActivitiesList();
        }
    }, [currentPage]);

    const handleOptionSelect = (selectedOption) => {
        setSelectSort(selectedOption.label);
    };

    const handlePageChange = (event, value) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setCurrentPage(value);
    };

    return (
        <div className={styles.home}>
            <Helmet>
                <title>ActivityList</title>
                <link rel="icon" href={FAVICON} />
                <meta
                    name="description"
                    content="Activitylist wants to help people to make more good experiences with friends and like-minded people in their free time – to achieve it’s ultimate goal of making humans happier"
                />
            </Helmet>
            <div className={styles.home__container}>
                <div className={styles.home__content}>
                    {/* <h3>
                        {cardsData?.length > 0
                            ? `${cardsData.length} things to do`
                            : 'Find things to do'}
                    </h3> */}
                    {/* <div className={styles.header__container}>
                        <h3>
                            {cardsData?.length > 0 || searchedData?.length > 0
                                ? `${cardsData?.length || searchedData?.length} things t do`
                                : 'Find things to do'}
                        </h3>
                        {(cardsData?.length > 0 || searchedData?.length > 0) && (
                            <div className={styles.activity__actions}>
                                <StyledButton bg="none" onClick={() => setOpenShareModal(true)}>
                                    Share
                                </StyledButton>
                                <StyledButton bg="none" onClick={() => setOpenActivityModal(true)}>
                                    Add activity
                                </StyledButton>
                            </div>
                        )}
                    </div> */}
                    {/* <p className={styles.infoText}>
                        See which activities you can do at a given place and time, matching your
                        criteria.{' '}
                    </p> */}
                    <form className={styles.searchForm} onSubmit={handleSubmit}>
                        <div className={styles.form__control}>
                            <div className={styles.form__field}>
                                <div className={styles.Search__Icon}>
                                    <SearchOutlined sx={{ width: '23px', height: '23px' }} />
                                </div>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    // value={searchInputRef?.current?.value}
                                    ref={searchInputRef}
                                    // onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className={styles.form__control}>
                            <div className={styles.form__field}>
                                <div className={styles.Search__Icon}>
                                    <LocationOn sx={{ width: '23px', height: '23px' }} />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <input
                                        type="text"
                                        placeholder="Location"
                                        name="location"
                                        value={userLocationSearch}
                                        onChange={handleCityChange}
                                        autoComplete="off"
                                        list="places"
                                        ref={locationInputRef}
                                    />
                                    <datalist id="places">
                                        {autocompleteCities?.map((city, i) => (
                                            <option key={i}>{city}</option>
                                        ))}
                                    </datalist>
                                </div>
                            </div>
                        </div>
                        <div className={styles.action__buttons}>
                            <StyledButton
                                onClick={() => setShowFilterInMobile(true)}
                                bg={'#F2F2F2'}
                                customColor={'#000'}
                            >
                                Filter
                            </StyledButton>
                            <StyledButton bg={'#3B83B8'} type="submit">
                                Search
                            </StyledButton>
                        </div>
                    </form>

                    <div className={styles.home__contentBottom}>
                        <div
                            className={[
                                styles.filter_section,
                                showFilterInMobile ? styles.showFilter : '',
                            ].join(' ')}
                        >
                            <FilterSection getActivitiesList={getActivitiesList} setCurrentPage={setCurrentPage} currentPage={currentPage} setShowFilterInMobile={setShowFilterInMobile} />
                        </div>
                        <div className={styles.section_card}>
                            <div className={styles.header_sectionCard}>
                                <h3>
                                    {totalActivities > 0
                                        ? `${
                                              totalActivities
                                          } things to do`
                                        : 'Find things to do'}
                                </h3>
                                {/* <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                                    <p>Sort:</p>
                                    <DropdownSort
                                        options={SortOptions}
                                        onSelect={handleOptionSelect}
                                    />
                                </div> */}
                            </div>
                            <Cards />
                            {cardsData && (
                                <div className={styles.Pagination}>
                                    <Pagination
                                        count={Math.ceil(totalActivities / 100)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        defaultPage={+queryParam?.page || currentPage}
                                        // disabled={!listedTokens}
                                        showFirstButton
                                        showLastButton
                                        sx={{
                                            '& .MuiPaginationItem-root': {
                                                color: '#1F62AF',
                                            },
                                            '& .Mui-selected': {
                                                color: '#fff',
                                                backgroundColor: '#1F62AF !important',
                                                '&:hover': {
                                                    color: '#1F62AF',
                                                },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={styles.rightSection}>
                            <div className={styles.ratingStats}>
                                <RatingStats totalActivities={totalActivities || 0} />
                            </div>
                            {/* <div className={styles.people_section}>
                                <UsersSection baseUrl={BASE_URI} />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <ShareActivityModal open={openShareModal} setOpen={setOpenShareModal} />
            <AddActivityModal open={openActivityModal} setOpen={setOpenActivityModal} />
        </div>
    );
};

export default Homepage;

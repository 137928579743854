import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';
import { StoreContext } from 'store/useStore';
import { initialState, reducer } from 'store/reducer';
import Homepage from 'pages/Homepage';
import routes from 'constants/routes';
import Header from 'components/Header';
import Filter from 'pages/Filter';
import Activity from 'pages/Activity';
import { GlobalLoader } from 'hoc/loader';
import UserProfile from 'pages/UserProfile';
import { SnackbarProvider } from 'hoc/snackbar';
import UserActivity from 'pages/UserActivity';
import MyLists from 'pages/Lists';
import ChatPage from 'pages/ChatPage';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'helper/firebase';
import actions from 'store/action';
import MaintenanceModeModal from 'components/MaintenanceModeModal';
import LoginModal from 'components/LoginModal';
import FiltersPage from 'pages/Filters';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FAVICON from 'assets/icons/favicon.ico';
import axios from 'axios';
import queryString from 'query-string';
import { getCelcius, getCurrentData, getVisibilityInitialValue } from 'helper/weatherFormat';

const BASE_URI = process.env['REACT_APP_BASE_URI']
const CURRENT_LOCATION_API = process.env['REACT_APP_IP_API'];
const IP_API_KEY = process.env['REACT_APP_IP_API_ACCESS_KEY'];
const WEATHER_BASE_URI = 'https://api.openweathermap.org/data/2.5/forecast';
const WEATHER_API_KEY = process.env['REACT_APP_WEATHER_API_KEY'];

const App = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [maintenanceMode, setMaintenanceMode] = useState(false);
    const user = localStorage.getItem('user') || null;

    const getProfilePic = async (userId) => {
        try {
            const res = await axios.get(`${BASE_URI}/user_details/${userId}/profile_picture`, {
                responseType: 'arraybuffer',
            });
            // if (res.statusCode === 404) {
            //     setProfilePic(null);
            // }
            const resultData = new Blob([res.data], { type: 'image/png' });
            // // Create URL from Blob
            var imageUrl = URL.createObjectURL(resultData);
            dispatch({
                type: actions.SET_USER_PROFILE_PIC,
                payload: imageUrl,
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: actions.SET_USER_PROFILE_PIC,
                payload: '',
            });
        }
    };

    useEffect(() => {
        
        if (user) {
            const userDetail = JSON.parse(localStorage.getItem('user'));
            console.log(userDetail,':userDetail')
            getProfilePic(userDetail.id)
            dispatch({
                type: actions.SET_USER,
                payload: userDetail,
            });

        }
    }, [user]);

    const getWeatherDetails = async (ipData) => {
        try {
            
            const url = queryString.stringifyUrl({
                url: WEATHER_BASE_URI,
                query: {
                    appid: WEATHER_API_KEY,
                    lon: ipData?.longitude,
                    lat: ipData?.latitude,
                },
            });
            const res = await axios.get(url);
            console.log({ res })
            if (res.status !== 200) throw res.statusText;
            const data = res.data;
            let todayData = getCurrentData(data);
            const weatherDataInfo = {
                temperature: getCelcius(todayData.main.temp),
                wind: todayData.wind.speed,
                visibility: getVisibilityInitialValue(todayData.weather[0].main),
            };
            dispatch({
                type: actions.SET_WEATHER_INFO,
                payload: weatherDataInfo,
            });
            
        } catch (error) {
            console.log(error);
            
        }
    };

    const getCurrentIp = async () => {
        try {
            // setLoading(true, 'Fetching Current Location...');
            const res = await axios.get(`${CURRENT_LOCATION_API}?apiKey=${IP_API_KEY}`);
            if (res.status !== 200) throw res.statusText;
            const data = res.data;
            console.log({ data }, ':currIp')
             
                // setUserLocationSearch(queryParam?.Location);
            dispatch({
                type: actions.SET_LOCATION,
                payload: {
                    city: data?.city,
                    latitude: data.latitude,
                    longitude: data.longitude,
                },
            });
            
            await getWeatherDetails(data);
            // setLoading(false);
        } catch (error) {
            console.log(error);
            // setLoading(false);
        }
    };

    useEffect(() => {
        getCurrentIp()
    }, [])

    return (
        <StoreContext.Provider value={{ state, dispatch }}>
            <HelmetProvider>
                <Helmet>
                    <title>ActivityList</title>
                    <link rel="icon" href={FAVICON} />
                    <meta
                        name="description"
                        content="Activitylist wants to help people to make more good experiences with friends and like-minded people in their free time – to achieve it’s ultimate goal of making humans happier"
                    />
                </Helmet>
                <SnackbarProvider>
                    <Router>
                        <Header />
                        <GlobalLoader>
                            <Routes>
                                <Route path={routes.HOMEPAGE} element={<Homepage />} />
                                <Route path={routes.FILTER} element={<Filter />} />
                                <Route path={routes.FILTERS} element={<FiltersPage />} />
                                <Route path={routes.ACTIVITY} element={<Activity />} />
                                <Route path={routes.ACTIVITY_WITH_TAB} element={<Activity />} />
                                <Route path={routes.PROFILE} element={<UserProfile />} />
                                <Route path={routes.PROFIE_WITH_TAB} element={<UserProfile />} />
                                <Route path={routes.USER_ACTIVITY} element={<UserActivity />} />
                                <Route path={routes.MY_LIST} element={<MyLists />} />
                                <Route path={routes.CHAT} element={<ChatPage />} />
                                <Route path={routes.LOGIN} element={<LoginModal />} />
                            </Routes>
                        </GlobalLoader>
                    </Router>
                    <MaintenanceModeModal open={maintenanceMode} setOpen={setMaintenanceMode} />
                </SnackbarProvider>
            </HelmetProvider>
        </StoreContext.Provider>
    );
};

export default App;

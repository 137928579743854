import moment from 'moment';

export const getCurrentData = (data) => {
    let enteredDate = moment().format('MM-DD-YYYY');
    let currData = data.list.find((item) => {
        let dtDate = moment(item.dt_txt).format('MM-DD-YYYY');
        return dtDate == enteredDate;
    });
    return currData;
};

export const getCelcius = (k) => {
    let c = Number(k) - 273.15;
    return c.toFixed(0).toString();
};

export const getVisibilityInitialValue = (value) => {
    console.log(value);
    switch (value.toLowerCase()) {
        case 'clouds':
            return 'cloudy';
        case 'rain':
            return 'rainy';
        case 'clear':
            return 'clearsky';
        default:
            break;
    }
};
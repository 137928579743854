import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styles from './header.module.scss';
import routes from 'constants/routes';
import logo from 'assets/images/logoNew.png';
import logoNew from 'assets/images/activitylist-blue 1.png';
import { Button, styled, Popover } from '@mui/material';
import { useState } from 'react';
import LoginModal from 'components/LoginModal';
import { StoreContext } from 'store/useStore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'firebase/auth';
import { auth } from 'helper/firebase';
import { useSnackBar } from 'hoc/snackbar';
import LikeModal from 'components/LikeModal';
import FrequencyModal from 'components/FrequencyModal';
import actions from 'store/action';
import axios from 'axios';
import {
    SearchOutlined,
    LocationOn,
    ChatOutlined,
    Reviews,
    Search,
    Style,
    Menu,
} from '@mui/icons-material';
import { fetchPlace } from 'helper/fetchPlace';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import ProfilePicture from 'components/ProfilePicture';
import moment from 'moment';
import queryString from 'query-string';

const StyledButton = styled(Button)(({ bg }) => ({
    background: bg,
    textTransform: 'Capitalize',
    color: '#fff',
    borderRadius: '5px',
    width: '123px',
    height: '41px',
    fontSize: 18,
    '&:hover': {
        background: bg,
    },
    '@media (max-width: 476px)': {
        fontSize: '15px !important',
    },
}));

const BASE_URI = process.env['REACT_APP_BASE_URI'];
const CURRENT_LOCATION_API = process.env['REACT_APP_IP_API'];
const WEATHER_API_KEY = process.env['REACT_APP_WEATHER_API_KEY'];
const WEATHER_BASE_URI = 'https://api.openweathermap.org/data/2.5/forecast';

const Header = () => {
    const { state, dispatch } = useContext(StoreContext);
    const location = useLocation();
    const { userLocation, user, weatherDataInfo } = state;

    const isHideNav =
        location.pathname !== routes.HOMEPAGE &&
        !location.pathname.includes('rating_like') &&
        !location.pathname.includes('rating_done');

    const searchParams = new URLSearchParams(location.search);
    const searchTermValue = searchParams.get('search_term');

    const queryParam = {};
    searchParams.forEach((value, key) => {
        queryParam[key] = value;
    });

    const [searchTerm, setSearchTerm] = useState(searchTermValue || '');
    const [userLocationSearch, setUserLocationSearch] = useState(
        queryParam?.Location || userLocation?.city || '',
    );
    const isLoginPage =
        location.pathname.split('/')[1] == 'login' || location.pathname.split('/')[1] == 'register';
    const [openModal, setOpenModal] = useState(false);
    const [openLikeModal, setOpenLikeModal] = useState(false);
    const [openFreqModal, setOpenFreqModal] = useState(false);
    const navigate = useNavigate();
    const { snackBarInfo, snackBarError } = useSnackBar();
    const filterValues = JSON.parse(localStorage.getItem('filterValues')) || '';

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [autocompleteCities, setAutocompleteCities] = useState([]);
    const [autocompleteErr, setAutocompleteErr] = useState('');
    console.log(userLocation, ':user');
    const [coordinates, setCoordinates] = useState(
        Object.keys(queryParam).length > 0
            ? [queryParam?.Longitude, queryParam?.Latitude]
            : [userLocation?.longitude, userLocation?.latitude],
    );

    const [isSticky, setIsSticky] = useState(false);

    const locationInputRef = useRef(null);
    const localUser = JSON.parse(localStorage.getItem('user') || '{}')

    console.log({ userLocation, weatherDataInfo })

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if((userLocationSearch === '' || !userLocationSearch) && userLocation?.city !== '') {
            setUserLocationSearch(userLocation?.city)
        }

    }, [userLocation])

    console.log({ userLocationSearch })

    const debounceBack = useCallback(
        debounce(async (newValue) => {
            const res = await fetchPlace(newValue);
            console.log(res);
            !autocompleteCities.includes(newValue) &&
                res.features &&
                setAutocompleteCities(res.features.map((place) => place.place_name));
            setCoordinates(res.features[0]?.center);
            dispatch({
                type: actions.SET_LOCATION,
                payload: {
                    city: newValue,
                    latitude: res.features[0]?.center[1],
                    longitude: res.features[0]?.center[0],
                },
            });
            res.error ? setAutocompleteErr(res.error) : setAutocompleteErr('');
        }, 1000),
        [],
    );

    const handleCityChange = (e) => {
        setUserLocationSearch(e.target.value);
        debounceBack(e.target.value);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const setLoading = (value, msg) => {
        dispatch({
            type: actions.SET_GLOABL_LOADING,
            payload: {
                loading: value,
                text: msg ? msg : '',
            },
        });
    };

    const getCurrentData = (data) => {
        let enteredDate = moment().format('MM-DD-YYYY');
        let currData = data.list.find((item) => {
            let dtDate = moment(item.dt_txt).format('MM-DD-YYYY');
            return dtDate == enteredDate;
        });
        return currData;
    };

    const getCelcius = (k) => {
        let c = Number(k) - 273.15;
        return c.toFixed(0).toString();
    };

    const getVisibilityInitialValue = (value) => {
        console.log(value);
        switch (value.toLowerCase()) {
            case 'clouds':
                return 'cloudy';
            case 'rain':
                return 'rainy';
            case 'clear':
                return 'clearsky';
            default:
                break;
        }
    };

    const getWeatherDetails = async (ipData) => {
        try {
            setLoading(true, 'Fetching Weather Details...');
            const url = queryString.stringifyUrl({
                url: WEATHER_BASE_URI,
                query: {
                    appid: WEATHER_API_KEY,
                    lon: ipData?.longitude,
                    lat: ipData?.latitude,
                },
            });
            const res = await axios.get(url);
            if (res.status !== 200) throw res.statusText;
            const data = res.data;
            let todayData = getCurrentData(data);
            const weatherDataInfo = {
                temperature: getCelcius(todayData.main.temp),
                wind: todayData.wind.speed,
                visibility: getVisibilityInitialValue(todayData.weather[0].main),
            };
            dispatch({
                type: actions.SET_WEATHER_INFO,
                payload: weatherDataInfo,
            });
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getCurrentIp = async () => {
        try {
            setLoading(true, 'Fetching Current Location...');
            const res = await axios.get(CURRENT_LOCATION_API);
            if (res.status !== 200) throw res.statusText;
            const data = res.data;
            if (Object.keys(queryParam).length === 0) {
                setUserLocationSearch(res.data.city);
                dispatch({
                    type: actions.SET_LOCATION,
                    payload: {
                        city: data.city || data.country_name,
                        latitude: data.latitude,
                        longitude: data.longitude,
                    },
                });
            } else {
                setUserLocationSearch(queryParam?.Location);
                dispatch({
                    type: actions.SET_LOCATION,
                    payload: {
                        city: queryParam?.Location,
                        latitude: queryParam.Latitude,
                        longitude: queryParam.Longitude,
                    },
                });
            }
            await getWeatherDetails(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const queryParametersFromPayload = (payload) => {
        const filterPayload = Object.entries(payload).reduce((acc, [key, value]) => {
            if (value !== '' && value !== 0 && !(Array.isArray(value) && value.length === 0)) {
                acc[key] = value;
            }
            return acc;
        }, {});
        return filterPayload;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true, 'Getting activities by search term...');
            const payload =  {
                      search_term: searchTerm,
                    //   Location: userLocation?.city,
                    //   Latitude: userLocation?.latitude,
                    //   Longitude: userLocation?.longitude,
                      limit: 100,
                      offset: 0
                  };

            // const filledPayload = queryParametersFromPayload(payload);
            const option = {
                'Content-Type': 'application/json',
            };
            const res = await axios.post(`${BASE_URI}/activities`, payload, option);
            if (res.status !== 200) throw res.statusText;
            const { data } = res;
            dispatch({
                type: actions.SET_DATA,
                payload: {
                    data: data.data,
                    totalActivities: data.total_rows
                },
            });

            if ('limit' in payload) {
                delete payload.limit;
            }
            if ('offset' in payload) {
                delete payload.offset;
            }
            
            const queryString1 = new URLSearchParams(payload).toString();
            console.log(userLocation, ':ul');
            console.log(coordinates, ':coo');
            const localCoordinates = coordinates || [
                userLocation?.longitude,
                userLocation?.latitude,
            ];
            localStorage.setItem('coordinates', JSON.stringify(localCoordinates));
            navigate(`/?${queryString1}`);
            setLoading(false);
        } catch (error) {
            console.log(error);
            snackBarError(error.toString());
            setLoading(false);
        }
    };

    const getCapitalizeName = (text) => {
        const nameString = text.toString();
        const capitalizeName =
            nameString.charAt(0).toUpperCase() + nameString.slice(1, nameString.lenght);
        return capitalizeName;
    };

    const logout = async () => {
        dispatch({
            type: actions.SET_USER,
            payload: null,
        });
        localStorage.removeItem('jwt');
        localStorage.removeItem('user');
        handleClose();
    };

    // useEffect(() => {
    //     if (!userLocation) getCurrentIp();
    // }, []);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.scrollY >= 80) {
    //             setIsSticky(true);
    //         } else {
    //             setIsSticky(false);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <header
            className={[
                styles.header,
                isLoginPage && styles.hideHeader,
                isHideNav && styles.mobileHideNavbar,
            ].join(' ')}
        >
            <div className={styles.header__rightContent}>
                <div className={styles.logo}>
                    <Link to={routes.HOMEPAGE}>
                        <img src={logoNew} alt="" />
                    </Link>
                </div>
                {/* {isActivitPage && (
                    <div className={styles.rating__actions}>
                        <span onClick={() => setOpenLikeModal(true)}>Liking</span>
                        <span onClick={() => setOpenFreqModal(true)}>Frequency</span>
                    </div>
                )} */}
                <div className={styles.searchBox}>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.search_container}>
                            <div className={styles.search_bar}>
                                <div>
                                    <input
                                        className={styles.input_1}
                                        type="text"
                                        placeholder={'Location'}
                                        name="location"
                                        value={userLocationSearch}
                                        onChange={handleCityChange}
                                        // pattern={autocompleteCities.join('|')}
                                        autoComplete="off"
                                        list="places"
                                        ref={locationInputRef}
                                        // onChange={(e) => setUserLocationSearch(e.target.value)}
                                    />
                                    <datalist id="places">
                                        {autocompleteCities?.map((city, i) => (
                                            <option key={i}>{city}</option>
                                        ))}
                                    </datalist>
                                </div>

                                <input
                                    className={styles.input_2}
                                    placeholder="Search"
                                    type="text"
                                    name="searchTerm"
                                    value={searchTerm}
                                    defaultValue={userLocation?.city}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                            <button className={styles.search_button}>
                                <Search />
                            </button>
                        </div>
                        {/*  */}
                        {/*  */}
                    </form>
                </div>
            </div>
            {/* {displaySearch && ( */}

            {/* )} */}

            <div className={styles.button_container}>
                <Link className={styles.more_btn} to={'/filter'}>
                    <Menu />
                </Link>

                {!state.user && (
                    <button
                        className={styles.login__btn}
                        onClick={() => {
                            navigate('/login');
                        }}
                    >
                        {'Sing Up'}
                    </button>
                )}

                {state.user && (
                    <button
                        style={{
                            cursor: 'pointer',
                            background: 'none',
                            border: 'none',
                            outline: 'none',
                        }}
                        onClick={(e) => handleClick(e)}
                    >
                        {state.userProfilePic || localUser?.user_detail?.profile_picture ? (
                            <ProfilePicture customStyle={{ height: '48px', width: '48px' }} userId={localUser?.id} />
                        ) : (
                            <div className={styles.blankProfile}></div>
                        )}
                        {/* <ProfilePicture
                            userId={user.id}
                            customStyle={{ height: '48px', width: '48px', borderRadius: '50%' }}
                            profilePic={state.userProfilePic}
                            
                        /> */}
                    </button>
                )}
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                anchorPosition={{
                    top: 20,
                    left: 0,
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{ marginTop: '10px' }}
            >
                <div className={styles.profileDrop}>
                    <div
                        className={styles.profileAction}
                        onClick={() => {
                            navigate(`/profile/${state.user.id}`);
                            handleClose();
                        }}
                    >
                        <AccountCircleIcon />
                        <span>Profile</span>
                    </div>
                    <div className={styles.hrline} />
                    {/* <div
                        className={styles.profileAction}
                        onClick={() => {
                            navigate('/chat');
                            handleClose();
                        }}
                    >
                        <ChatOutlined />
                        <span>Messages</span>
                    </div> */}
                    <div
                        className={styles.profileAction}
                        onClick={() => {
                            navigate(`/${state.user.username}/rating_like`);
                            handleClose();
                        }}
                    >
                        <Reviews />
                        <span>Rating</span>
                    </div>
                    <div className={styles.hrline} />
                    <div className={styles.profileAction} onClick={logout}>
                        <LogoutIcon />
                        <span>Logout</span>
                    </div>
                </div>
            </Popover>
            {/* <LoginModal open={openModal} setOpen={setOpenModal} /> */}
            <LikeModal open={openLikeModal} setOpen={setOpenLikeModal} />
            <FrequencyModal open={openFreqModal} setOpen={setOpenFreqModal} />
        </header>
    );
};

export default Header;
